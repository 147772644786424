import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import ReactInfiniteScroller from 'react-infinite-scroller';

export const InfiniteScroller = ({
  children,
  pageStart,
  loadMore,
  loading,
  loadingText,
  initialLoad,
  hasMore,
  showInfinite,
  threshold,
  useWindow,
  parentRef,
}) => {
  const showScroller = showInfinite && Boolean(loadMore);

  const loader = (
    <div className="loader" key="loader">
      {loadingText}
    </div>
  );

  return showScroller ? (
    <ReactInfiniteScroller
      pageStart={pageStart}
      loadMore={loadMore}
      hasMore={hasMore}
      initialLoad={initialLoad}
      useWindow={useWindow}
      threshold={threshold}
      getScrollParent={() => parentRef?.current}
    >
      {loading ? [...children, loader] : children}
    </ReactInfiniteScroller>
  ) : (
    children
  );
};

InfiniteScroller.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  pageStart: PropTypes.number,
  loadMore: PropTypes.func,
  loading: PropTypes.bool,
  initialLoad: PropTypes.bool,
  hasMore: PropTypes.bool,
  showInfinite: PropTypes.bool,
  threshold: PropTypes.number,
  useWindow: PropTypes.bool,
  parentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.any})]),
};

InfiniteScroller.defaultProps = {
  pageStart: 1,
  showInfinite: true,
  threshold: 100,
  useWindow: false,
  loadingText: 'Loading ...',
};

export default InfiniteScroller;
